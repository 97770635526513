<div fxLayout="column" fxFill>
  <div class="bar" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="30">
    <div *ngFor="let job of catalogSyncJobs" class="progress-item">
      <div>{{ 'Retrieving catalog' | translate}} {{ job.name }}</div>
      <mat-progress-bar mode="buffer" [value]="job.progress"></mat-progress-bar>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-card class="catalog-item" *ngFor="let item of filteredCatalogApps">
      <div
        class="content-wrapper"
        fxLayout="row"
        (click)="showSummaryDialog(item.name, item.catalog.id, item.catalog.train)"
      >
        <div class="logo-container">
          <div class="logo">
            <img [src]="item.icon_url" [src-fallback]="imagePlaceholder"/>
          </div>
          <div class="badge-area" [class.badge-official]="item.catalog.label === officialCatalog">
            <div class="capitalized-text">
              {{ item.catalog.label }}
            </div>
            <div class="train-badge capitalized-text">
              {{ item.catalog.train }}
            </div>
          </div>
        </div>
        <div class="content" fxLayout="column" fxLayoutAlign="center center">
          <div class="catalog-title">
            <h3 textLimiter threshold="15" content="{{item.name}}"></h3>
          </div>
          <div class="catalog-version" textLimiter threshold="32" fxFlex="100%" content="{{ item.latest_human_version }}">
          </div>
          <div class="button-row" (click)="$event.stopPropagation()">
            <button
              *ngIf="item.healthy; else showError"
              [disabled]="!selectedPool"
              mat-button
              ix-auto
              ix-auto-type="button"
              ix-auto-identifier="{{ item.name }}-install"
              (click)="doInstall(item.name, item.catalog.id, item.catalog.train)"
            >
              {{ 'Install' | translate }}
            </button>

            <ng-template #showError>
              <div class="unhealthy-warning" [matTooltip]="item.healthy_error">
                <mat-icon class="icon">report_problem</mat-icon>
                {{ 'Unhealthy' | translate }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </mat-card>
    <entity-empty *ngIf="!filteredCatalogApps.length" [conf]="emptyPageConf"></entity-empty>
  </div>
</div>
