<div *ngIf="label" class="label-container">
  <label class="label">
    {{label}}
    <span *ngIf="required" class="required">*</span>
  </label>
  <tooltip *ngIf="tooltip" [header]="label" class="tooltip" [message]="tooltip"></tooltip>
</div>

<div class="input-container" [class.disabled]="isDisabled">
  <span *ngIf="prefixIcon" class="prefix-icon">
    <mat-icon>{{prefixIcon}}</mat-icon>
  </span>
  <input
    #ixInput
    matInput
    [class.prefix-padding]="prefixIcon"
    [class.password-field]="isPasswordField()"
    [required]="required"
    [disabled]="isDisabled"
    [type]="getType()"
    [value]="formatted"
    (input)="input(ixInput.value)"
    (blur)="blur()"
    [autocomplete]="autocomplete"
    [autocapitalize]="autocapitalize"
  >
  <span *ngIf="shouldShowResetInput()" class="reset-input">
    <mat-icon (click)="resetInput()" role="img" fontSet="mdi-set" fontIcon="mdi-close-circle"></mat-icon>
  </span>
  <button
    *ngIf="type === 'password'"
    mat-icon-button type="button"
    class="toggle_pw"
    (click)="onPasswordToggled()">
    <i class="material-icons" matTooltip="Hide" *ngIf="showPassword">visibility</i>
    <i class="material-icons" matTooltip="Show" *ngIf="!showPassword">visibility_off</i>
  </button>
</div>
<ix-errors [control]="controlDirective.control" [label]="label"></ix-errors>

