export enum ApiEventMessage {
  Changed = 'changed',
  Added = 'added',
  Removed = 'removed',
  Result = 'result',
  Connected = 'connected',
  NoSub = 'nosub',
  Pong = 'pong',
  Sub = 'sub',
}
