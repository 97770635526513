<div *ngIf="config" class="control-group">
  <div *ngFor="let control of config.controls">
    <toolbar-button *ngIf="control.type == 'button'" [config]="control" [controller]="controller$" ></toolbar-button>
    <toolbar-slider *ngIf="control.type == 'slider'" [config]="control" [controller]="controller$ | cast"></toolbar-slider>
    <toolbar-input *ngIf="control.type == 'input'" [config]="control" [controller]="controller$" ></toolbar-input>
    <toolbar-select *ngIf="control.type == 'select'" [config]="control" [controller]="controller$" ></toolbar-select>
    <toolbar-menu *ngIf="control.type == 'menu'" [config]="control" [controller]="controller$"></toolbar-menu>
    <toolbar-multimenu *ngIf="control.type == 'multimenu'" [config]="control" [controller]="controller$"></toolbar-multimenu>
    <toolbar-multiselect *ngIf="control.type == 'multiselect'" [config]="control" [controller]="controller$"></toolbar-multiselect>
  </div>
</div>
