<div mat-dialog-content>
  <div class="title-div" fxLayout="row" fxLayoutAlign="space-between center">
    <strong class="catalog-name" textLimiter threshold="40" content="{{ catalog.label }} {{ 'Catalog Summary' | translate }}"></strong>
    <span class="status active" *ngIf="catalog.healthy">{{ 'Healthy' | translate }}</span>
    <span class="status other" *ngIf="!catalog.healthy">{{ 'Unhealthy' | translate }}</span>
  </div>
  <div class="resource" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="status-filter">
      <span>{{ 'Train' | translate }}:</span>
      <mat-form-field class="form-element ">
        <mat-select
          [(ngModel)]="selectedTrain"
          (ngModelChange)="onOptionChanged()"
          ix-auto ix-auto-type="select" ix-auto-identifier="train-select"
        >
          <mat-option *ngFor="let option of trainOptions" value="{{ option | translate }}">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="status-filter">
      <span>{{ 'Status' | translate }}:</span>
      <mat-form-field class="form-element ">
        <mat-select [(ngModel)]="selectedStatus"  (ngModelChange)="onOptionChanged()"
                  ix-auto ix-auto-type="select" ix-auto-identifier="status-select">
          <mat-option *ngFor="let option of statusOptions" value="{{ option | translate }}">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="version-list">
    <div fxLayout="row" fxLayoutAlign="center start" class="detail-row row-light" >
      <span fxFlex="25%">{{'Train' | translate }}</span>
      <span fxFlex="25%">{{'App' | translate }}</span>
      <span fxFlex="50%" >{{'Version' | translate }}</span>
    </div>
    <div *ngIf="filteredItems.length > 0;else empty">
      <div *ngFor="let item of filteredItems; let i = index" class="detail-row value-row" [ngClass]="{'row-dark': i%2==0, 'row-light': i%2==1}">
        <div fxLayout="row" fxLayoutAlign="center start">
          <div fxFlex="25%" class="item-train">
            <span textLimiter threshold="15" content="{{ item.train }}"></span>
          </div>
          <span fxFlex="25%" class="item-app" >
            <span textLimiter threshold="15" content="{{ item.app }}"></span>
          </span>
          <span fxFlex="50%" class="info-col" >
            <span textLimiter threshold="22" content="{{ item.human_version }}"></span><span>{{ versionStatusLabel(item) }}</span>
          </span>
        </div>
      </div>
    </div>
    <ng-template #empty>
      <div fxLayout="row" fxLayoutAlign="start start" class="detail-row value-row row-dark">
        {{ 'No Item' | translate }}
      </div>
    </ng-template>

  </div>
</div>

<div class="button-bar" mat-dialog-actions align="end">
  <button
    mat-button
    class="mat-button mat-secondary"
    (click)="dialogRef.close(false)"
    ix-auto ix-auto-type="button" ix-auto-identifier="CLOSE"
  >
    {{ helptext.actionBtnText.close | translate }}
  </button>
</div>
