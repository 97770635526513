<div class="wrapper">
  <div class="header">
    <div class="header-overlay">
      <div class="logo-wrapper">
        <mat-icon *ngIf="systemType === ProductType.Core" svgIcon="truenas_core_logo_full" class="logo" alt="TrueNAS CORE logo"></mat-icon>
        <mat-icon *ngIf="systemType === ProductType.Enterprise" svgIcon="truenas_enterprise_logo_full" class="logo" alt="TrueNAS ENTERPRISE logo"></mat-icon>
        <mat-icon *ngIf="systemType === ProductType.Scale || systemType === ProductType.ScaleEnterprise" svgIcon="truenas_scale_logo_full" class="logo" alt="TrueNAS SCALE logo"></mat-icon>
      </div>
      <div id="title" *ngIf="extraMsg">{{ helptext.tagline | translate }}</div>
    </div>
  </div>

  <div class="content-wrapper">
    <div class="help">{{ helptext.help | translate }}</div>
    <div class="line-item">
      <div fxFlex="10%"><mat-icon class="bullet-icon">assignment</mat-icon></div>
      <div fxFlex="90%" class="medium-font">{{ helptext.docsA | translate }}
        <a href="https://www.truenas.com/docs/" target="_blank" class="external-link">
          {{ helptext.docsB | translate }}</a> {{ helptext.docsC | translate }}
      </div>
    </div>

    <div class="line-item">
      <div fxFlex="10%"><mat-icon class="bullet-icon">group</mat-icon></div>
      <div fxFlex="90%" class="medium-font">{{ helptext.forumsA | translate}} <a href="https://www.ixsystems.com/community/"
				target="_blank" class="external-link">
        {{ helptext.forumsB | translate }}</a> {{ helptext.forumsC | translate }} {{systemType}} {{ helptext.forumsD }}.
      </div>
    </div>

    <div class="line-item" *ngIf="systemType === ProductType.Core">
      <div fxFlex="10%"><img id="ix-img" src="assets/images/iX_Logomark.svg"></div>
      <div fxFlex="90%" class="medium-font">{{ helptext.contactA | translate }}
        <a href="https://www.ixsystems.com/truenas/?utm_source=truenas+core+ui&utm_medium=os&utm_campaign=welcome"
					target="_blank" class="external-link">
          {{ helptext.contactB | translate }}</a> {{ helptext.contactC | translate }}
        <a href="https://www.ixsystems.com/quote-form/?utm_source=truenas+core+ui&utm_medium=os&utm_campaign=welcome"
					target="_blank" class="external-link">{{ helptext.contactD | translate }}</a>
      </div>
    </div>
    <div id="open-source">TrueNAS {{systemType}} {{ helptext.opensourceA | translate }} <a href="https://github.com/freenas/" target="_blank"
			class="external-link">{{ helptext.opensourceB | translate }}</a> {{ helptext.opensourceC | translate }}
    </div>
    <div id="findme" *ngIf="extraMsg">
      {{ helptext.findagainA | translate }} <mat-icon>settings</mat-icon> {{ helptext.findagainB | translate }}
      <span class="emphasis">{{ helptext.findagainC | translate }}</span>
    </div>
  </div>

</div>

<div mat-dialog-actions id="actions">
  <div fxFlex="45%" fxFlex.xs="100" class="copyright-txt">TrueNAS {{ systemType }}® © {{copyrightYear}} -
    <a href="http://www.ixsystems.com" target="_blank" title="iXsystems, Inc." class="external-link">
      iXsystems, Inc</a>.
  </div>
  <div id="button-wrapper" fxFlex="55%" fxFlex.xs="100">
    <button
      *ngIf="extraMsg; else aboutButton"
      mat-button class="mat-button mat-primary"
      (click)="dialogRef.close(false)"
      (click)="turnOffWelcomeDialog()"
		  ix-auto ix-auto-type="button" ix-auto-identifier="CLOSE"
    >
      {{ helptext.actionBtnText.splash | translate | uppercase }}
    </button>

    <ng-template #aboutButton>
      <button
        mat-button class="mat-button mat-primary"
        (click)="dialogRef.close(false)"
        (click)="turnOffWelcomeDialog()"
			  ix-auto ix-auto-type="button" ix-auto-identifier="CLOSE"
      >
        {{ helptext.actionBtnText.about | translate | uppercase }}
      </button>
    </ng-template>

  </div>
</div>
