<h1 mat-dialog-title id="err-title">
  <mat-icon class="warning-icon">report_problem</mat-icon>
  {{ title | translate }}
</h1>
<div mat-dialog-content id="err-md-content">
  <div id="err-message-wrapper"><span [innerHTML]="message"></span></div>
  <div
    *ngIf="backtrace"
    class="more-info"
    (click)="toggleOpen()"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="backtrace-toggle"
  >
    <mat-icon *ngIf="isCloseMoreInfo">add_circle_outline</mat-icon>
    <mat-icon *ngIf="!isCloseMoreInfo">remove_circle_outline</mat-icon>
    <span>{{ 'More info...' | translate }}</span>
  </div>
  <div id="err-bt-panel" class="backtrace-panel" [ngClass]="{'open':!isCloseMoreInfo}" *ngIf="backtrace">
    <div class="textarea" id="err-bt-text" readonly matInput>{{ 'Error' | translate }}: {{backtrace}}</div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button class="mat-button mat-accent" (click)="dialogRef.close(true)"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="CLOSE">{{"Close" | translate}}</button>
  <button mat-button class="mat-button mat-primary" *ngIf="logs" (click)="downloadLogs()"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="DOWNLOAD LOGS">{{"Download Logs" | translate}}</button>
</div>
