<h1 mat-dialog-title>{{ title | translate }}</h1>
<mat-dialog-content
  class="entity-job-dialog"
  ix-auto
  ix-auto-type="dialog"
  ix-auto-identifier="title"
>

  <div *ngIf="job?.state === JobState.Running">
    <div fxLayout="row" fxLayoutAlign="space-between baseline" fxLayoutGap="8px">
      <span>{{ job.description ? job.description : job.method }}</span>
      <small *ngIf="!hideProgressValue">{{ progressTotalPercent || 0 | number: '1.2-2' }}%</small>
    </div>

    <mat-progress-bar
      color="primary"
      [value]="progressTotalPercent"
      [mode]="progressTotalPercent ? 'determinate' : 'indeterminate'"
      class="entity-job-progress"
    >
    </mat-progress-bar>
  </div>

  <div class="entity-job-description" *ngIf="description" [innerHTML]="description"></div>

  <div *ngIf="!description && !job">
    <mat-progress-bar
      color="primary"
      mode="indeterminate"
      class="entity-job-progress"
    >
    </mat-progress-bar>
  </div>

  <div *ngIf="!description">
    <span *ngIf="altMessage" [innerHTML]="altMessage"></span>
    <div *ngIf="!altMessage">
      <span *ngIf="job?.state === JobState.Waiting">{{ 'Waiting' | translate }}</span>
      <span *ngIf="job?.state !== JobState.Waiting">{{ 'Fetching data...' | translate }}</span>
    </div>
  </div>

  <div *ngIf="showRealtimeLogs && realtimeLogs">
    <h5>{{ 'Logs' | translate }}</h5>
    <pre class="message">{{ realtimeLogs }}</pre>
  </div>
</mat-dialog-content>

<div mat-dialog-actions>
  <button
    mat-button
    *ngIf="showAbortButton && job?.state === JobState.Running"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="ABORT"
    (click)="abortJob()"
  >
    {{ 'Abort' | translate }}
  </button>
  <button
    *ngIf="showCloseButton"
    class="mat-dialog-close"
    mat-icon-button
    mat-dialog-close="close"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="CLOSE"
  >
    <mat-icon>remove</mat-icon>
  </button>
</div>
