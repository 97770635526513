<mat-checkbox
  color="primary"
  [checked]="value"
  [required]="required"
  [disabled]="isDisabled"
  (change)="onCheckboxChanged($event)"
  (blur)="onTouch()"
>
  {{ label }}
  <span *ngIf="required" class="required">*</span>
</mat-checkbox>
<tooltip *ngIf="tooltip" [header]="label" class="tooltip" [message]="tooltip"></tooltip>
<ix-errors [control]="controlDirective.control" [label]="label"></ix-errors>